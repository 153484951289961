<template>
  <div>
    <!-- 侧边栏部分，手机和电脑端共用 -->
    <div class="sidebar-container" :class="{ 'sidebar-hidden': isSidebarHidden && isMobile }">
      <el-row class="tac">
        <el-col :span="isMobile ? 24 : 4" class="sidebar-menu" v-if="username">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-vertical-demo"
            background-color="#1F2937"
            text-color="#fff"
            active-text-color="#19A9FD"
            unique-opened
            router
            @open="handleOpen"
            @close="handleClose"
            @select="handleMenuSelect"
          >
            <div class="user">
              <div class="user-div">
                <div class="user-phone">
                  <p>{{ username }}</p>
                </div>
                <div v-if="owner === '0'" class="user-vip">
                  超级管理员
                </div>
                <div v-else class="user-vip">
                  经销商
                </div>
              </div>
            </div>

            <!-- 菜单项 -->
            <el-sub-menu index="dashboard">
              <template #title>
                <el-icon><location /></el-icon>
                <span>仪表盘</span>
              </template>
              <el-menu-item index="/">
                <el-icon><icon-menu /></el-icon>
                首页
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="codeManagement">
              <template #title>
                <el-icon><document /></el-icon>
                <span>兑换码管理</span>
              </template>
              <el-menu-item index="/generateCodeZS">
                <el-icon><icon-menu /></el-icon>
                赠送系统
              </el-menu-item>
              <el-menu-item index="/generateCode">
                <el-icon><icon-menu /></el-icon>
                生成兑换码
              </el-menu-item>
              <el-menu-item index="/codeRecords">
                <el-icon><icon-menu /></el-icon>
                兑换码记录
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="gameManagement">
              <template #title>
                <el-icon><Memo /></el-icon>
                <span>游戏管理</span>
              </template>
              <el-menu-item v-if="owner === '0'" index="/newGame">
                <el-icon><icon-menu /></el-icon>
                发布新游戏
              </el-menu-item>
              <el-menu-item index="/gameList">
                <el-icon><icon-menu /></el-icon>
                游戏列表
              </el-menu-item>
              <el-menu-item v-if="owner === '0'" index="/gameDepots">
                <el-icon><icon-menu /></el-icon>
                游戏DLC管理
              </el-menu-item>
              <el-menu-item v-if="owner === '0'" index="/gametickets">
                <el-icon><icon-menu /></el-icon>
                游戏票证管理
              </el-menu-item>
              <!-- <el-menu-item index="/gameUpdate">
                <el-icon><icon-menu /></el-icon>
                更新游戏
              </el-menu-item> -->
              <el-menu-item v-if="owner === '0'" index="/depotsManagement">
                <el-icon><icon-menu /></el-icon>
                DepotsID管理
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="userManagement">
              <template #title>
                <el-icon><user /></el-icon>
                <span>用户管理</span>
              </template>
              <el-menu-item index="/order" v-if="owner === '0'">
                <el-icon><icon-menu /></el-icon>
                订单管理
              </el-menu-item>
              <el-menu-item index="/lotteryRecord" v-if="owner === '0'">
                <el-icon><icon-menu /></el-icon>
                抽奖记录
              </el-menu-item>
              <el-menu-item index="/registeredUsers">
                <el-icon><icon-menu /></el-icon>
                已注册用户
              </el-menu-item>
            </el-sub-menu>

            <el-sub-menu v-if="owner === '0'" index="adminManagement">
              <template #title>
                <el-icon><setting /></el-icon>
                <span>后台管理</span>
              </template>
              <el-menu-item index="/announcements">
                <el-icon><icon-menu /></el-icon>
                发布公告
              </el-menu-item>
              <el-menu-item index="/distributors">
                <el-icon><icon-menu /></el-icon>
                经销商列表
              </el-menu-item>
              <el-menu-item index="/salesOverview">
                <el-icon><icon-menu /></el-icon>
                经销商经营状况
              </el-menu-item>
              
              <el-menu-item index="/addDistributor">
                <el-icon><icon-menu /></el-icon>
                添加经销商
              </el-menu-item>
              <el-menu-item index="/codeSettings">
                <el-icon><icon-menu /></el-icon>
                权限组管理
              </el-menu-item>

              <el-menu-item index="/editScore">
                <el-icon><icon-menu /></el-icon>
                信用分管理
              </el-menu-item>

              <el-menu-item index="/dashboard">
                <el-icon><icon-menu /></el-icon>
                更多功能
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-col>

        <el-col 
          :span="20" 
          :class="{ righ: username && !isMobile }" 
          :style="username && isMobile ? { padding: '20px' } : {}" 
          v-if="!isMobile"
        >
          <router-view />
        </el-col>



      </el-row>

      <!-- 关闭侧边栏按钮 -->
      <el-button v-if="isMobile" class="sidebar-close-btn" @click="toggleSidebar"> 《 收起侧边栏 </el-button>
    </div>

    <!-- 侧边栏关闭时的按钮 -->
<div 
  v-if="isSidebarHidden && isMobile && username" 
  class="sidebar-toggle-btn" 
  @click="toggleSidebar"
>
  》 
</div>

    <!-- 手机端的内容渲染逻辑 -->
    <div v-if="isMobile">
      <!-- 如果侧边栏关闭，则渲染全屏内容 -->
      <div v-if="isSidebarHidden" class="mobile-fullscreen-content">
        <router-view :key="selectedRoute" />
      </div>
      <!-- 如果侧边栏打开，则不显示内容 -->
      <div v-else></div>
    </div>

    
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isMobile = ref(window.innerWidth <= 768);
    const isSidebarHidden = ref(isMobile.value); // 如果是手机端，默认隐藏侧边栏
    const selectedRoute = ref('/home'); // 默认路由

    // 切换侧边栏显示/隐藏
    const toggleSidebar = () => {
      isSidebarHidden.value = !isSidebarHidden.value;
    };

    // 处理菜单选择
    const handleMenuSelect = (index) => {
      if (isMobile.value) {
        selectedRoute.value = index; // 更新当前选中的路由
        router.push(index); // 更新路由
        isSidebarHidden.value = true; // 自动隐藏侧边栏
      }
    };

    const username = computed(() => store.state.user.username);
    const owner = computed(() => store.state.user.owner);
    const activeMenu = computed(() => route.path);

    // 监听窗口大小变化，动态更新 `isMobile` 和 `isSidebarHidden`
    window.addEventListener('resize', () => {
      isMobile.value = window.innerWidth <= 768;
      if (isMobile.value) {
        isSidebarHidden.value = true; // 手机端默认隐藏侧边栏
      } else {
        isSidebarHidden.value = false; // 桌面端显示侧边栏
      }
    });

    return {
      username,
      owner,
      activeMenu,
      isMobile,
      isSidebarHidden,
      toggleSidebar,
      selectedRoute,
      handleMenuSelect,
    };
  },
};
</script>

<style scoped>
*, html {
  padding: 0;
  margin: 0;
}

.tac {
  display: flex;
  height: 100vh;
}

.sidebar-menu {
  height: 100vh;
  background-color: #1F2937;
  color: white;
  overflow: auto;
}

.righ {
  height: 100vh;
  /* padding: 20px; */
}

.user {
  width: 100%;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #19A9FD 0%, rgba(0, 0, 0, 0) 100%);
}

.user-div {
  margin: 0 auto;
  text-align: center;
}

.user-div p {
  font-size: 1.5rem;
  color: #fff;
}

.user-vip {
  width: 9rem;
  height: 1.5rem;
  margin: 0 auto;
  color: #fff;
  background-color: #A4ADB3;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

/* 手机端样式 */
.sidebar-hidden {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-mobile-full {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #1F2937;
}

.sidebar-toggle-btn {
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 1000;
  background-color: #1F2937;
  color: white;
  padding: 10px;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 0 5px 5px 0;
}

.sidebar-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: white;
}

.mobile-fullscreen-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 100;
}
</style>
