<template>
  <div class="GameList">
    <el-container>
      <el-header style="display: flex; align-items: center; justify-content: space-between;">
        <h1>游戏票证管理</h1>
        <div>
          <el-button type="success" @click="openAddDepotDialog" style="margin-left: 20px;">添加票证</el-button>
          <el-button type="primary" @click="batchAddDialogVisible = true" style="margin-left: 20px;">批量添加票证</el-button>
        </div>
      </el-header>
      <el-main>
        <el-input
          v-model="searchQuery"
          placeholder="搜索游戏ID、名称或TicketID"
          @input="fetchGames"
          clearable
          prefix-icon="el-icon-search"
          style="margin-bottom: 20px;"
        ></el-input>
        <el-table :data="games" v-if="games.length > 0" style="width: 100%;" @sort-change="handleSortChange">
          <el-table-column prop="gameid" label="游戏ID" width="150"></el-table-column>
          <el-table-column prop="title" label="游戏名称" width="150"></el-table-column>
          <el-table-column prop="ticket_id" label="TicketID" width="100"></el-table-column>
          <el-table-column prop="ticket" label="Ticket" width="1000"></el-table-column>
          <el-table-column prop="add_time" label="添加时间" width="180" sortable></el-table-column>
          <el-table-column v-if="owner === '0'" label="操作" width="180">
            <template #default="scope">
              <el-button @click="openEditDialog(scope.row)" type="primary" size="mini">编辑</el-button>
              <el-button @click="deleteGame(scope.row.gameid,scope.row.ticket_id)" type="danger" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty description="没有数据可显示"></el-empty>
        </div>
        <el-pagination
          v-if="total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          style="margin-top: 20px; text-align: center;"
        ></el-pagination>

        <!-- 编辑票证的模态框 -->
        <el-dialog title="编辑游戏" v-model="dialogVisible" :fullscreen="isMobile" width="50%" @close="resetForm">
          <el-form :model="currentGame" label-width="100px" class="edit-form">
            <el-form-item label="游戏ID">
              <el-input v-model="currentGame.gameid" disabled></el-input>
            </el-form-item>
            <el-form-item label="游戏名称">
              <el-input v-model="currentGame.title" disabled></el-input>
            </el-form-item>
            <el-form-item label="TicketID">
              <el-input v-model="currentGame.ticketid" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Ticket">
              <el-input v-model="currentGame.ticket" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="添加时间">
              <el-date-picker v-model="currentGame.add_time" type="datetime"></el-date-picker>
            </el-form-item>

          </el-form>
          <template #footer>
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveGame">保存</el-button>
          </template>
        </el-dialog>

        <!-- 批量添加游戏的对话框 -->
        <el-dialog title="批量添加游戏票证" v-model="batchAddDialogVisible" width="50%" :fullscreen="isMobile">
          <el-input
            type="textarea"
            v-model="batchGamesText"
            placeholder="每行一条游戏记录，字段用英文逗号分隔。顺序为：游戏ID, TicketID, Ticket, 添加时间（标准日期时间格式）"
            rows="10"
          ></el-input>
          <template #footer>
            <el-button @click="batchAddDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="batchAddGames">提交</el-button>
          </template>
        </el-dialog>

        <!-- 添加票证的模态框 -->
        <el-dialog title="添加票证" v-model="addDepotDialogVisible" :fullscreen="isMobile" width="50%">
          <el-form :model="newDepot" label-width="100px" class="edit-form">
            <el-form-item label="游戏ID">
              <el-input v-model="newDepot.gameid" type="number"></el-input>
            </el-form-item>
            <el-form-item label="TicketID">
              <el-input v-model="newDepot.ticketid" type="number"></el-input>
            </el-form-item>
            <el-form-item label="Ticket">
              <el-input v-model="newDepot.ticket" type="textarea"></el-input>
            </el-form-item>
            <el-form-item label="添加时间">
              <el-date-picker v-model="newDepot.add_time" type="datetime"></el-date-picker>
            </el-form-item>
          </el-form>
          <template #footer>
            <el-button @click="addDepotDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="saveNewDepot">保存</el-button>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from '../axios';
import { ElMessage } from 'element-plus';
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'GameTickets',
  setup() {
    const store = useStore();
    const owner = computed(() => store.state.user.owner);
    const isMobile = computed(() => window.innerWidth <= 768); // 判断是否为移动设备
    return {
      owner,
      isMobile
    };
  },
  data() {
    return {
      games: [],
      dialogVisible: false,
      batchAddDialogVisible: false, // 控制批量添加对话框的显示
      batchGamesText: '', // 存储批量添加的游戏数据
      currentGame: {
        id: '',
        gameid: '',
        title: '',
        add_time: '',
        ticket_id: '',
        ticket: ''
      },
      newDepot: { // 新增的 Depot 数据
        gameid: '',
        depot: '',
        add_time: ''
      },
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      sort: {
        prop: '',
        order: ''
      },
      addDepotDialogVisible: false, // 控制添加DLC对话框的显示
    };
  },
  created() {
    this.fetchGames();
  },
  methods: {
    // 将日期转换为标准日期时间格式
    formatDate(date) {
      const d = new Date(date);
      if (isNaN(d.getTime())) {
        return ''; // 如果日期无效，返回空字符串
      }
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      const seconds = String(d.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    
    async fetchGames() {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=getGameTickets', { token, search: this.searchQuery, page: this.currentPage, size: this.pageSize, sort: this.sort });
        this.games = response.data.games || [];
        this.total = parseInt(response.data.total, 10) || 0;
      } catch (error) {
        console.error('Error fetching games:', error);
        ElMessage.error('获取票证列表失败');
      }
    },
    handleSortChange({ prop, order }) {
      this.sort = { prop, order };
      this.fetchGames();
    },
    openEditDialog(game) {
      this.currentGame = { ...game };
      this.dialogVisible = true;
    },
    async saveGame() {
      try {
        // 转换 add_time 为标准日期时间格式
        this.currentGame.add_time = this.formatDate(this.currentGame.add_time);

        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=updateGameTicekt', {
          token,
          game: JSON.stringify(this.currentGame)
        });
        if (response.data.success) {
          ElMessage.success('票证列表已更新');
          this.dialogVisible = false;
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error updating game:', error);
        ElMessage.error('更新失败，请稍后重试');
      }
    },
    async deleteGame(gameid, ticket_id) {
      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=deleteGameTicket', { token, gameid, ticket_id });
        if (response.data.success) {
          ElMessage.success('票证删除成功');
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error deleting game:', error);
        ElMessage.error('删除失败，请稍后重试');
      }
    },
    openAddDepotDialog() {
      this.addDepotDialogVisible = true;
    },
    async saveNewDepot() {
      try {
        // 转换 add_time 为标准日期时间格式
        this.newDepot.add_time = this.formatDate(this.newDepot.add_time);

        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=addNewGameTickets', {
          token,
          depot: JSON.stringify(this.newDepot)
        });
        if (response.data.success) {
          ElMessage.success('票证添加成功');
          this.addDepotDialogVisible = false;
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error adding depot:', error);
        ElMessage.error('添加票证失败，请稍后重试');
      }
    },
    async batchAddGames() {
      if (!this.batchGamesText.trim()) {
        ElMessage.error('请输入游戏数据');
        return;
      }

      const games = this.batchGamesText.trim().split('\n').map(line => {
        const [gameid, ticket_id, ticket, add_time] = line.split(',');
        return {
          gameid,
          ticket_id,
          ticket,
          add_time: this.formatDate(add_time) // 转换 add_time 为标准日期时间格式
        };
      });

      try {
        const token = localStorage.getItem('user-token');
        const response = await axios.post('/index.php?action=batchAddGamesTickets', {
          token,
          games: JSON.stringify(games)
        });

        if (response.data.success) {
          ElMessage.success('票证批量添加成功');
          this.batchAddDialogVisible = false;
          this.fetchGames();
        } else {
          ElMessage.error(response.data.message);
        }
      } catch (error) {
        console.error('Error batch adding games:', error);
        ElMessage.error('批量添加票证失败，请稍后重试');
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.fetchGames();
    },
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
      this.fetchGames();
    },
    resetForm() {
      this.currentGame = {
        id: '',
        gameid: '',
        title: '',
        add_time: '',
        ticket: '',
        ticket_id: ''
      };
    }
  }
};
</script>

<style scoped>
.GameList {
  padding: 20px;
}

.el-main {
  width: 100%;
}

.el-table {
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* 移动端模态框全屏 */
  .el-dialog {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding: 20px;
  }

  .el-form-item {
    margin-bottom: 10px;
  }

  .el-dialog__footer {
    padding: 10px;
  }

  .el-input, .el-select {
    width: 100%;
  }

  .el-button {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
